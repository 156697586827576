<template>
  <header>
    <nav class="navbar navbar-expand-lg navbar-light">
      <router-link to="/dashboard" class="navbar-brand mr-auto">
        <mdc-logo height="45" viewBox="0 0 150 50" />
      </router-link>
      <div class="navbar-nav account-nav" v-click-outside="closeAll">
        <div
          v-if="$store.state.user.id != ''"
          href="#"
          class="toggle"
          role="button"
          @click="toggleAccountMenu"
          :class="{
            active: menuOpen,
          }"
        >
          <div class="image">
            <img :src="$store.state.user.avatar" />
          </div>
          <div class="detail">
            <p class="fullname">
              {{ fullName }}
            </p>
            <p class="email">{{ $store.state.user.email }}</p>
          </div>
        </div>
        <div v-if="menuOpen" class="dropdown">
          <!-- Uncomment this code when endpoint /locale is ready to use
          <div v-if="activeDropdown === 'language'">
            <div class="header">
              <p class="title">
                <span @click.stop="setActiveDropdown('')" class="back">
                  <mpx-icon :variant="'arrow-left'"></mpx-icon>
                </span>
                {{ $t("layout.navbar.change_language") }}
              </p>
            </div>
            <div class="divider"></div>
            <div class="menu">
              <a
                v-for="locale in locales"
                :key="locale.code"
                class="menu-item"
                :href="`/locale?locale=${locale.code}`"
              >
                {{ locale.name }}
                <mpx-icon
                  v-if="$t('i18n') === locale.code"
                  :variant="'check'"
                ></mpx-icon>
              </a>
            </div>
          </div>

          <div v-else> -->
          <div class="header">
            <div class="image">
              <img :src="$store.state.user.avatar" />
            </div>
            <p class="fullname">
              {{ fullName }}
            </p>
            <p class="email">{{ $store.state.user.email }}</p>
          </div>
          <div class="divider"></div>
          <div class="menu">
            <div class="divider"></div>
            <!-- Uncomment this code when endpoint /locale is ready to use
              <a class="menu-item" @click.stop="setActiveDropdown('language')">
                {{ $t("layout.navbar.change_language") }}
                <span class="current-language">{{ activeLocale }}</span>
              </a> -->
            <a class="menu-item" href="/users/sign_out">
              {{ $t("layout.navbar.sign_out") }}
            </a>
          </div>
          <div class="footer">
            <div class="links">
              <a href="https://mekari.com/kebijakan-privasi/">
                {{ $t("layout.navbar.privacy") }}
              </a>
              <a href="https://mekari.com/persyaratan-layanan/">
                {{ $t("layout.navbar.terms_of_use") }}
              </a>
              <a href="https://mekari.com/tentang-mekari-account/">
                {{ $t("layout.navbar.about") }}
              </a>
            </div>
            <div class="copyright">Mekari © 2021</div>
          </div>
          <!-- </div> -->
        </div>
      </div>
    </nav>
  </header>
</template>

<script lang="ts">
import { defineComponent } from "vue";
// import MpxIcon from "@mekari/pixel/src/js/components/Icon/index.vue";
import MdcLogo from "@/assets/images/logo-mekari-developers.svg";

interface Locale {
  code: string;
  name: string;
}

interface Data {
  menuOpen: boolean;
  activeDropdown: string;
  locales: Locale[];
}

export default defineComponent({
  components: {
    // AvatarDefaultIcon,
    MdcLogo,
    // MpxIcon,
  },
  data(): Data {
    return {
      menuOpen: false,
      activeDropdown: "",
      locales: [
        {
          code: "en",
          name: this.$t("general.english"),
        },
        {
          code: "id",
          name: this.$t("general.indonesia"),
        },
      ],
    } as Data;
  },
  computed: {
    activeLocale(): string | undefined {
      return this.locales.find(
        (locale: Locale) => locale.code == this.$t("i18n")
      )?.name;
    },
    fullName(): string {
      return `${this.$store.state.user.firstName} ${
        this.$store.state.user.lastName || ""
      }`.trim();
    },
  },
  methods: {
    toggleAccountMenu(): void {
      if (this.menuOpen) {
        this.closeAll();
        return;
      }

      this.menuOpen = true;
    },
    setActiveDropdown(type: string): void {
      this.activeDropdown = type;
    },
    closeAll(): void {
      this.setActiveDropdown("");
      this.menuOpen = false;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_color";

.navbar {
  padding: 0px 24px;
  background: #ffffff;
  border-bottom: 1px solid #c9ced7;
  font-size: 14px;

  .navbar-brand img {
    width: 124px;
    margin: 20px 0px;
  }

  .account-nav {
    margin: 16px 0px;
    height: 48px;
    position: relative;

    .fullname {
      font-weight: 400;
      line-height: 20px;
      color: $color-black;
    }

    .email {
      font-size: 12px;
      line-height: 16px;
      color: $color-gray-slate;
    }

    .toggle {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 4px;
      border-radius: 6px;

      &:after {
        order: 2;
      }

      &:hover,
      &.active {
        background: $color-almost-white;
        cursor: pointer;
      }

      .image {
        flex: none;
        order: 0;
        flex-grow: 0;
        padding: 4px;
        margin: 0px;

        img {
          width: 32px;
          height: 32px;
          display: block;
        }
      }

      .detail {
        margin-left: 8px;
        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 0px 4px;

        .fullname {
          font-weight: 600;
        }

        .email {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }

    .dropdown {
      position: absolute;
      right: 0;
      top: 100%;
      margin-top: 4px;
      width: 272px;
      height: max-content;
      background: #ffffff;
      border: 1px solid $color-gray-smoke;
      box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
        0px 4px 6px -2px rgba(0, 0, 0, 0.05);
      border-radius: 6px;
      overflow: hidden;
      z-index: 9999;

      .header {
        background: $color-almost-white;
        border-radius: 6px 6px 0px 0px;
        padding: 16px;

        .image {
          text-align: center;
          margin-bottom: 8px;

          img {
            display: inline-block;
            width: 48px;
            height: 48px;
          }
        }

        .fullname {
          font-weight: 600;
          text-align: center;
        }

        .email {
          text-align: center;
        }

        .title {
          font-weight: 600;

          .back {
            &:hover {
              cursor: pointer;
            }

            i {
              display: block;
              margin-right: 4px;
              float: left;
              margin-top: -2px;
            }
          }
        }
      }

      .divider {
        width: 100%;
        height: 1px;
        background: $color-gray-smoke;
        opacity: 0.5;
      }

      .menu-item {
        padding: 12px 16px;
        display: block;
        color: $color-black;
        text-decoration: none;

        &:hover {
          text-decoration: none;
          background: $color-almost-white;
          cursor: pointer;
        }

        .add-account {
          color: $color-gray-slate;
          padding-left: 12px;
          vertical-align: middle;
        }

        .add-account-icon {
          float: none;
          width: 24px;
          height: 24px;
        }

        .current-language {
          color: $color-gray-slate;
          float: right;
        }

        i {
          float: right;
          width: 20px;
          height: 20px;
        }
      }

      .footer {
        padding: 12px 16px;
        font-size: 10px;
        line-height: 12px;
        color: $color-gray-slate;

        .links {
          margin-bottom: 4px;
          display: flex;
          flex-direction: row;
          align-items: flex-start;

          a {
            color: $color-gray-slate;
            flex-grow: 0;
            margin: 0px 6px;
            font-size: 10px;
            text-decoration: none;

            &:hover {
              text-decoration: none;
              cursor: pointer;
            }

            &:first-child {
              margin-left: 0px;
            }

            &:last-child {
              flex-grow: 1;
              margin-right: 0px;
            }
          }
        }
      }
    }

    .accounts {
      display: flex !important;
      flex-direction: row;
      align-items: center;
      padding: 8px 16px !important;

      .image {
        flex: none;
        order: 0;
        flex-grow: 0;
        padding-right: 12px;
        margin: 0px;

        img {
          width: 32px;
          height: 32px;
          display: block;
        }
      }
    }
  }
}
</style>
