<template>
  <div class="sidebar" :class="{ collapsed: collapsed }">
    <span
      v-show="collapsed"
      class="sidebar-toggle"
      v-tooltip.right="'Expand'"
      @click="toggle"
    >
      <MpxIcon :variant="'chevron-right'"></MpxIcon>
    </span>
    <span
      v-show="!collapsed"
      class="sidebar-toggle"
      v-tooltip.right="'Collapse'"
      @click="toggle"
    >
      <MpxIcon :variant="'chevron-left'"></MpxIcon>
    </span>
    <div class="menu-item-group">
      <router-link to="/dashboard/applications">
        <MpxIcon variant="stock-available" primary-color="slate" />
        <span class="text-icon">Applications</span>
      </router-link>
      <router-link :to="ROUTES.HMAC_VALIDATOR">
        <MpxIcon variant="stock-available" primary-color="slate" />
        <span class="text-icon">HMAC Validator</span>
      </router-link>
      <!-- <a href="#">
        <MpxIcon variant="settings" primary-color="slate" />
        <span class="text-icon">API References</span>
      </a> -->
      <a href="/docs/kb" target="_blank">
        <MpxIcon variant="file-doc" primary-color="slate" />
        <span class="text-icon">Documentation</span>
      </a>
      <!-- <a href="#">
        <MpxIcon variant="individual-goal" primary-color="slate" />
        <span class="text-icon">Users & Permissions</span>
      </a> -->
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MpxIcon from "@mekari/pixel/src/js/components/Icon/index.vue";
import { ROUTES, RouteType } from "@/constants/routes";

interface Data {
  ROUTES: RouteType;
  collapsed: boolean;
}

export default defineComponent({
  components: {
    MpxIcon,
  },
  data() {
    return {
      ROUTES: {
        HMAC_VALIDATOR: ROUTES.HMAC_VALIDATOR,
      },
      collapsed: false,
    } as Data;
  },
  computed: {},
  methods: {
    toggle(): void {
      if (this.collapsed) {
        this.collapsed = false;
      } else {
        this.collapsed = true;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_color";

.sidebar {
  position: absolute;
  width: 220px;
  background: #ffffff;
  border-right: 1px solid $color-gray-smoke;
  height: calc(100vh - 82px);
  padding: 2px 6px;
  transition: width 0.3s, padding 0.3s;

  .sidebar-toggle {
    position: absolute;
    display: block;
    border: 1px solid #d0d6dd;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    border-radius: 50%;
    height: 26px;
    width: 26px;
    right: -13px;
    background: #fff;
    top: 22px;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s;
  }

  &:hover {
    .sidebar-toggle {
      opacity: 1;
    }
  }

  &.collapsed {
    width: 56px;
    padding: 2px 0px;

    .menu-item-group {
      padding: 16px 8px;

      a {
        height: 40px;
        width: 40px;
        padding: 8px;

        i {
          margin-right: 0px;
        }
      }

      select {
        display: none;
      }

      .text-icon {
        display: none;
      }
    }
  }

  .menu-item-group {
    padding: 16px 8px;
    transition: padding 0.3s;

    a {
      display: block;
      color: $color-black;
      background: $color-white;
      font-size: 14px;
      line-height: 20px;
      padding: 8px 16px;
      text-overflow: "…";
      white-space: nowrap;
      overflow: hidden;
      text-decoration: none;
      width: 195px;
      height: 40px;
      transition: width 0.3s, height 0.3s, padding 0.3s;

      &:hover {
        text-decoration: none;
        cursor: pointer;
        background: $color-almost-white;
        border-radius: 6px;
      }

      &.router-link-active,
      &.router-link-active:hover {
        text-decoration: none;
        cursor: pointer;
        font-weight: 600;
        color: #4b61dd;
        background: #e0eeff;
        border-radius: 6px;
      }

      i {
        margin-right: 7px;
      }
    }

    select {
      margin-bottom: 8px;
    }
  }

  .divider {
    height: 1px;
    width: 100%;
    background: $color-gray-smoke;
    opacity: 0.5;
  }

  .text-icon {
    vertical-align: middle;
  }
}
</style>
