<template>
  <div id="manage-container">
    <manage-header-component />
    <manage-sidebar-component />
    <main>
      <router-view />
    </main>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ManageHeaderComponent from "@/components/layouts/ManageHeaderComponent.vue";
import ManageSidebarComponent from "@/components/layouts/ManageSidebarComponent.vue";

export default defineComponent({
  components: {
    ManageHeaderComponent,
    ManageSidebarComponent,
  },
});
</script>
