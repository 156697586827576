
import { defineComponent } from "vue";
import MpxIcon from "@mekari/pixel/src/js/components/Icon/index.vue";
import { ROUTES, RouteType } from "@/constants/routes";

interface Data {
  ROUTES: RouteType;
  collapsed: boolean;
}

export default defineComponent({
  components: {
    MpxIcon,
  },
  data() {
    return {
      ROUTES: {
        HMAC_VALIDATOR: ROUTES.HMAC_VALIDATOR,
      },
      collapsed: false,
    } as Data;
  },
  computed: {},
  methods: {
    toggle(): void {
      if (this.collapsed) {
        this.collapsed = false;
      } else {
        this.collapsed = true;
      }
    },
  },
});
