
import { defineComponent } from "vue";
import ManageHeaderComponent from "@/components/layouts/ManageHeaderComponent.vue";
import ManageSidebarComponent from "@/components/layouts/ManageSidebarComponent.vue";

export default defineComponent({
  components: {
    ManageHeaderComponent,
    ManageSidebarComponent,
  },
});
