
import { defineComponent } from "vue";
// import MpxIcon from "@mekari/pixel/src/js/components/Icon/index.vue";
import MdcLogo from "@/assets/images/logo-mekari-developers.svg";

interface Locale {
  code: string;
  name: string;
}

interface Data {
  menuOpen: boolean;
  activeDropdown: string;
  locales: Locale[];
}

export default defineComponent({
  components: {
    // AvatarDefaultIcon,
    MdcLogo,
    // MpxIcon,
  },
  data(): Data {
    return {
      menuOpen: false,
      activeDropdown: "",
      locales: [
        {
          code: "en",
          name: this.$t("general.english"),
        },
        {
          code: "id",
          name: this.$t("general.indonesia"),
        },
      ],
    } as Data;
  },
  computed: {
    activeLocale(): string | undefined {
      return this.locales.find(
        (locale: Locale) => locale.code == this.$t("i18n")
      )?.name;
    },
    fullName(): string {
      return `${this.$store.state.user.firstName} ${
        this.$store.state.user.lastName || ""
      }`.trim();
    },
  },
  methods: {
    toggleAccountMenu(): void {
      if (this.menuOpen) {
        this.closeAll();
        return;
      }

      this.menuOpen = true;
    },
    setActiveDropdown(type: string): void {
      this.activeDropdown = type;
    },
    closeAll(): void {
      this.setActiveDropdown("");
      this.menuOpen = false;
    },
  },
});
